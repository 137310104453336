import { Component } from "../../core/Component";
import { log } from "video.js";
export class DateComponent extends Component {
  // static className = ["qform-row"];
  constructor($root, options) {
    super($root, options);
    this.messages = options.messages;
    this.fieldId = options.id;
    this.typeId = options.typeId;
    this.label = options.label || "";
    this.hint = options.hint || "";
    this.type = options.type || "text";
    this.require = options.required === 1;
    this.placeholder = options.placeholder || " ";
    this.classes = options.classes || "";
    this.useDesign = options.useDesign;
    this.Datepicker = null;
    // установка пользовательских классов оформления
    this.wrapClasses = this.makeWrapUserClasses();
    this.max = options.max ? new Date(options.max) : null;
    this.min = options.min ? new Date(options.min) : null;
    this.name = `${options.name}`;
    this.id = `${options.name}_${options.formIndex}_${new Date().getTime()}`;
    this.datepickerId = `${options.name}_${options.formIndex}_datepicker_${new Date().getTime()}`;
    this.boxId = `${options.name}_${options.formIndex}_box_${new Date().getTime()}`;
    this.boxIdField = `${options.name}_${options.formIndex}_box-field_${new Date().getTime()}`;
    this.errorBox = `${options.name}_${options.formIndex}_error-box_${new Date().getTime()}`;
    this.errorBoxText = `${options.name}_${options.formIndex}_error-box_text_${new Date().getTime()}`;
    this.labelId = `${options.name}_${options.formIndex}_label_${new Date().getTime()}`;
  }
  toHTML() {
    return `
    <div class="qform-field datepickers qform_icon-date ${this.hint ? 'qform-hint' : ''} ${this.wrapClasses?.join(' ')}" id="${this.boxId}">
      <div class="qform-field_wrap">
      ${this.toHtmlOutLabelBox()}
      <div class="qform-field__block">
        ${this.toHtmlAttBox('out_left')}
          <div class="qform-field__block_box">
            <div class="qform-field__block_box__field" id="${this.boxIdField}">
              ${this.toHtmlAttBox('in_left')}
              <div class="qform-field__block_box__field-box">
                <input
                  type="text"
                  class="qform-field__block_box__field-box_input datepicker-here ${this.classes}"
                  ${this.min ? `data-min="${new Date(this.min).getDate()}.${new Date(this.min).getMonth() + 1}.${new Date(this.min).getFullYear()}"` : ""}
                  ${this.max ? `data-max="${new Date(this.max).getDate()}.${new Date(this.max).getMonth() + 1}.${new Date(this.max).getFullYear()}"` : ""}
                  maxlength=10
                  data-active="0"
                  data-class_show=""
                  name="${this.name}"
                  id="${this.id}"
                  placeholder="${this.placeholder}"
                  ${this.require ? 'required' : ""}
                >
                ${this.useDesign ? `<label for="${this.id}" class="qform-field__block_box__field-box_label">
                  ${this.label}
                  <span class="qform-field__importance-label">(${this.options.messages.unrequired})</span>
                  </label>` : ''}
              </div>
              ${this.toHtmlAttBox('in_right')}
            </div>
          </div>
          ${this.toHtmlAttBox('out_right')}
        </div>
      </div>
      ${this.toHtmlBottomBox()}
    </div>
    `;
  }
  dateParse(date) {
    let separator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ".";
    if (date) {
      let arr = date.split(separator);
      return `${arr[2]}-${arr[1]}-${arr[0]}`;
    } else {
      return "";
    }
  }
  dateParseToFormat(dateParsed) {
    const year = dateParsed.getFullYear();
    const month = dateParsed.getMonth() + 1;
    const date = dateParsed.getDate();
    return `${date < 10 ? '0' + String(date) : date}.${month < 10 ? '0' + String(month) : month}.${year}`;
  }
  init() {
    this.$input = this.$root.$el.querySelector(`#${this.id}`);
    this.$box = this.$root.$el.querySelector(`#${this.boxId}`);
    this.$boxField = this.$root.$el.querySelector(`#${this.boxIdField}`);
    this.$errorBox = this.$root.$el.querySelector(`#${this.errorBox}`);
    this.$errorBoxText = this.$root.$el.querySelector(`#${this.errorBoxText}`);
    this.listeners = [{
      eventName: "change",
      method: "onChange",
      el: this.$input
    }, {
      eventName: "focus",
      method: "onFocus",
      el: this.$input
    }, {
      eventName: "blur",
      method: "onBlur",
      el: this.$input
    }, {
      eventName: "keydown",
      method: "onKeyDown",
      el: this.$input
    }, {
      eventName: "input",
      method: "onInput",
      el: this.$input
    }, {
      eventName: "paste",
      method: "onPaste",
      el: this.$input
    }];
    super.init();
    setTimeout(() => {
      const storedData = JSON.parse(localStorage.getItem(this.options.formId));
      if (storedData && storedData[this.name]) {
        this.$input.value = storedData[this.name];
      }
    }, 200);
  }
  onChange(event) {
    event.preventDefault();
    this.$emit("Change:field", {});
    const storage = JSON.parse(localStorage.getItem(this.options.formId));
    if (storage) {
      storage[this.$input.name] = this.$input.value;
      localStorage.setItem(this.options.formId, JSON.stringify(storage));
    }
  }
  onKeyDown(event) {
    const {
      key,
      target
    } = event;
    const {
      selectionStart,
      selectionEnd,
      value
    } = target;
    //
    // if (key === 'Backspace' || key === 'Delete') {
    //   event.preventDefault();
    //   return;
    // }

    if (!/[0-9]|(ArrowRight)|(ArrowLeft)|/i.test(key) && !event.ctrlKey) {
      event.preventDefault();
      return;
    }
    if (/^[0-9]$/.test(key) && !event.ctrlKey) {
      event.preventDefault();
      const oldValue = value.slice(selectionStart, selectionEnd + 1);
      if (oldValue === '.') {
        const newValue = value.slice(0, selectionStart + 1) + key + value.slice(selectionEnd + 2);
        target.value = newValue;
        target.setSelectionRange(selectionStart + 2, selectionStart + 2);
      } else {
        const newValue = value.slice(0, selectionStart) + key + value.slice(selectionEnd + 1);
        target.value = newValue;
        target.setSelectionRange(selectionStart + 1, selectionStart + 1);
      }
    }
    if (event.key === 'Backspace' || event.key === 'Delete') {
      event.preventDefault(); // Предотвращаем стандартное поведение

      target.value = this.$input.value.slice(0, selectionStart - 1) + "0" + this.$input.value.slice(selectionStart);
    }
    if (this.$input.value.slice(0, 2) > 31 || this.$input.value.slice(0, 2) < 1) {
      target.value = "31" + value.slice(2);
    }
    if (this.$input.value.slice(3, 5) > 12 || this.$input.value.slice(3, 5) < 1) {
      target.value = this.$input.value.slice(0, 3) + "12" + this.$input.value.slice(5);
    }
    if (this.$input.value.slice(6) > 2100 || this.$input.value.slice(6) < 2000) {
      target.value = this.$input.value.slice(0, 6) + "2024";
    }
  }
  onInput(event) {
    if (!event.ctrlKey || !event.key === 'Control') {
      let value = event.target.value;
      value = value.replace(/[\-\,\\\/]/g, '.');
      event.target.value = value;
    }
  }
  onPaste(event) {
    const pastedText = event.clipboardData.getData('Text');
    if (!/[0-9]|[\.\-\,\\\/]/g.test(pastedText) || /[a-zA-Zа-яА-Я]/.test(pastedText)) {
      this.setMassageValid(this.messages.incorrectPasteValue);
      event.preventDefault();
    }
  }
  onFocus(event) {
    this.$boxField.classList.add('qform-field-focus');
  }
  onBlur(event) {
    this.$boxField.classList.remove('qform-field-focus');
    this.$input.value = this.correctDateValues(event.target.value);
    this.setMassageValid();
    this.validation();
  }
  correctDateValues(value) {
    const date = value.trim().split('.');
    const day = date[0];
    const month = date[1];
    const year = date[2];
    if (date.length === 3) {
      date.splice(0, 1, day.length === 1 ? `0${day}` : day);
      date.splice(1, 1, month.length === 1 ? `0${month}` : month);
      if (year.length === 1) {
        date.splice(2, 1, `200${year}`);
      } else if (year.length === 2) {
        date.splice(2, 1, `${+year <= 30 ? '20' : '19'}${year}`);
      } else if (year.length === 3) {
        let correctedYear;
        if (+year >= 0 && +year <= 300) {
          correctedYear = `2${year}`;
        } else if (+year > 300 && +year <= 999) {
          {
            correctedYear = `1${year}`;
          }
        }
        date.splice(2, 1, correctedYear);
      }
    }
    return date.join('.');
  }
  getValue() {
    return {
      id: this.fieldId,
      name: this.name,
      typeId: this.typeId,
      values: this.$input.value.trim().length > 0 && this.$input.value.trim().length === 10 ? [new Date(this.dateParse(this.$input.value)).getTime()] : []
    };
  }
  async getDatePicker() {
    await import( /* webpackChunkName: "DatePickerComponent" */'./DatePicker.component').then(data => {
      this.Datepicker = new data.Qdatepicker(this.$input, this.boxIdField, {
        messages: this.messages
      });
      this.Datepicker.init();
    });
  }
  async setClassField(data) {
    // .open | .open-gap
    if (data && typeof data.formStyleDate !== 'undefined' && data.formStyleDate.style !== 0) {
      if (data.formStyleDate.style === 1) {
        this.$input.dataset.class_show = 'open';
      }
      if (data.formStyleDate.style === 2) {
        this.$input.dataset.class_show = 'open-gap';
      }
    }
    if (!data.formStyleDate.useDesign) {
      //удаляем стилевые классы с корневого элемента
      let arrClass = this.$root.$el.classList.value.split(" ");
      arrClass.forEach(nameClass => {
        if (nameClass.trim()) {
          this.$root.$el.classList.remove(nameClass);
        }
      });
    }
    if (data && typeof data.formStyleFieldRequired !== 'undefined') {
      if (data.formStyleFieldRequired.type === 0 && this.require) {
        this.$box.classList.add('required');
      } else if (data.formStyleFieldRequired.type === 1 && !this.require) {
        this.$box.classList.add('not-required');
      }
      if (!data.formStyleFieldRequired.useDesign) {
        this.$box.classList.remove('required');
        this.$box.classList.remove('not-required');
      }
    }
    if (data.formStyleDate.style > 0 && !this.Datepicker) {
      await this.getDatePicker();
    }
  }
  checkDayValue() {
    const dayValue = this.$input.value.split('.')[0];
    return dayValue.length === 2 && +dayValue >= 1 && +dayValue <= 31;
  }
  checkMonthValue() {
    const monthValue = this.$input.value.split('.')[1];
    return monthValue.length === 2 && monthValue >= 1 && +monthValue <= 12;
  }
  checkYearValue() {
    const yearValue = this.$input.value.split('.')[2];
    return yearValue.length === 4 && +yearValue >= 1900 && +yearValue <= 2300;
  }
  validation() {
    let setMassageValid = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    let valid = false;
    if (this.require && this.$input.value.length === 0) {
      setMassageValid ? this.setMassageValid(this.messages.inputRequired) : null;
      //ограничение на java - 3584 для текстовых полей
    } else if (this.$input.value.trim().length > 3584) {
      setMassageValid ? this.setMassageValid(this.messages.inputTextTooLong) : null;
    } else if (this.$input.value.length > 0 && this.$input.value.length !== 10) {
      setMassageValid ? this.setMassageValid(this.messages.inputFormat) : null;
    } else if (this.max && new Date(this.dateParse(this.$input.value)) > this.max) {
      setMassageValid ? this.setMassageValid(this.messages.inputDateMax.replace("{0}", this.dateParseToFormat(this.max))) : null;
    } else if (this.min && new Date(this.dateParse(this.$input.value)) < this.min) {
      setMassageValid ? this.setMassageValid(this.messages.inputDateMin.replace("{0}", this.dateParseToFormat(this.min))) : null;
    } else if (this.$input.value && !this.checkDayValue()) {
      setMassageValid ? this.setMassageValid(this.messages.inputDateDayFormat || 1) : null;
    } else if (this.$input.value && !this.checkMonthValue()) {
      setMassageValid ? this.setMassageValid(this.messages.inputDateMonthFormat || 2) : null;
    } else if (this.$input.value && !this.checkYearValue()) {
      setMassageValid ? this.setMassageValid(this.messages.inputDateYearFormat || 3) : null;
    } else {
      valid = true;
      this.setMassageValid();
    }
    //если условие верно - поле валидно, и в нем есть данные, граница становится зеленой
    this.setSuccessValid(valid && this.$input.value.length);
    return valid;
  }
}