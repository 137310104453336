import { DomListener } from "../core/DomListener";
export class Component extends DomListener {
  constructor($root) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    super($root, options.listeners);
    this.$root = $root;
    this.name = `${options.formId}_${options.name}` || "";
    this.Emitter = options.Emitter;
    this.options = options;
    this.unsubscribers = [];
  }
  // Возвращает шаблон компонента
  toHTML() {
    return "";
  }
  toHtmlOutLabelBox() {
    return `
    <div class="qform-field-outside-label-box">
      <label for="${this.id}" class="qform-field-outside-label-box_label">
        ${this.label}
        <span class="qform-field__importance-label">(${this.options.messages.unrequired})</span>
      </label>
      <div class="qform-field_hint">
        <div class="qform-field_hint_message">
          <p>${this.hint}</p>
        </div>
      </div>
      <div class="qform-field_valid"></div>
    </div>
    `;
  }
  toHtmlAttBox(nameAttBox) {
    let attBox = {
      out_left: `
      <div class="qform-field_att-out_left">
        <div class="qform-field_hint">
          <div class="qform-field_hint_message">
            <p>${this.hint}</p>
          </div>
        </div>
        <div class="qform-field_valid"></div>
        <div class="qform-field_icon"></div>
      </div>
      `,
      out_right: `
      <div class="qform-field_att-out_right">
        <div class="qform-field_icon"></div>
        <div class="qform-field_valid"></div>
        <div class="qform-field_hint">
          <div class="qform-field_hint_message">
            <p>${this.hint}</p>
          </div>
        </div>
      </div>`,
      in_left: `
      <div class="qform-field_att-in_left">
        <div class="qform-field_hint">
          <div class="qform-field_hint_message">
            <p>${this.hint}</p>
          </div>
        </div>
        <div class="qform-field_valid"></div>
        <div class="qform-field_icon"></div>
      </div>
      `,
      in_right: `
      <div class="qform-field_att-in_right">
        <div class="qform-field_hint">
          <div class="qform-field_hint_message">
            <p>${this.hint}</p>
          </div>
        </div>
        <div class="qform-field_valid"></div>
        <div class="qform-field_icon"></div>
      </div>
      `
    };
    return attBox[nameAttBox];
  }
  toHtmlBottomBox() {
    let shift = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    return `
    <div class="qform-field__bottom-box">
      <div class="qform-field__bottom-box_shift"></div>
      <div class="qform-field__bottom-box_message">
        ${this.typeId !== 3 & this.typeId !== 5 & this.typeId !== 8 ? `
        <div class="qform-field_shift-box">
          <div class="qform-field_shift-valid"></div>
          <div class="qform-field_shift-hint"></div>
          <div class="qform-field_shift-icon"></div>
        </div>
        ` : ""}
        <div class="qform-field__bottom-box_message_block">
          <div class="qform-field__bottom-box_message__hint">${this.hint}</div>
          <div class="qform-field__bottom-box_message__error" id="${this.errorBoxText}"></div>
        </div>
      </div>
    </div>
    `;
  }
  init() {
    this.initDomListener();
    this.$emit("initField", {});
  }
  setUserClasses() {
    if (this.$root) {
      if (typeof this.classes === "string" && this.classes.length > 0) {
        let arrClasses = this.classes.trim().split(" ");
        arrClasses.forEach(classes => {
          this.$root.$el.classList.add(`wrap-${classes}`);
        });
      }
    }
  }
  makeWrapUserClasses() {
    let {
      noWrapPrefix = false
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    if (this.classes) {
      let arrClasses = this.classes.trim().split(" ");
      arrClasses = arrClasses.map(classes => {
        return (noWrapPrefix ? "" : "wrap-") + classes;
      });
      return arrClasses;
    }
  }
  setSuccessValid() {
    let status = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    if (status) {
      this.$box ? this.$box.classList.add("valid") : null;
    } else {
      this.$box ? this.$box.classList.remove("valid") : null;
    }
  }
  setMassageValid() {
    let message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    if (message) {
      // this.$root.$el.classList.add("errorValid");
      this.$box ? this.$box.classList.add("qform-error") : null;
      this.$errorBox ? this.$errorBox.classList.add("error") : null;
      this.$errorBoxText ? this.$errorBoxText.innerHTML = message : null;
      // this.setSuccessValid(false);
    } else {
      // this.$root.$el.classList.remove("errorValid");
      this.$box ? this.$box.classList.remove("qform-error") : null;
      this.$errorBox ? this.$errorBox.classList.remove("error") : null;
      this.$errorBoxText ? this.$errorBoxText.innerHTML = "" : null;
      // this.setSuccessValid();
    }
  }

  $emit(event) {
    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }
    this.Emitter.emit(event, ...args);
  }
  $subscribe(evetName, fn) {
    const subs = this.Emitter.subscribe(evetName, fn);
    this.unsubscribers.push(subs);
  }
  hidden() {
    //сохраняем свойство для корректного отображения компонента при выполнении show()
    if (getComputedStyle(this.$root.$el).display != 'none') {
      this.rootDisplayStyle = getComputedStyle(this.$root.$el).display;
    }
    this.$root.$el.style.display = "none";
  }
  show() {
    this.$root.$el.style.display = this.rootDisplayStyle;
  }
  destroy() {
    this.removeDomListener();
    this.unsubscribers.forEach(subs => subs());
    this.$root.$el.remove();
  }
}