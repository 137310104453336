const hostCloud = 'https://storage.yandexcloud.net/qform.static/qform-script';
export default class QFormBase {
  constructor() {
    let mode = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    this.mode = mode;
    this.version = "3.0";
    this.mode = mode;
    this.server_host = process.env.SERVER_HOST; // *here* qform27.ru/java || refactor.qform24.com ||  cdn-dev.qform24.com;
    this.upload_host = process.env.UPLOAD_HOST; // *here* qform27.ru/java || refactor.qform24.com ||  cdn-dev.qform24.com;
    this.init_contoller = "/init/api/request"; // Где взять формы GET
    this.init_contollerWidget = "/videowidget/"; // Где взять виджеты GET
    this.send_controller = "/send/api/request/send"; // Куда отправить форму
    this.upload_controller = "/form-upload/"; // Куда отправлять файлы
    this.upload_controller_chunks = "/form-upload-multi/"; // Куда отправлять файлы больше 100мб (частями)
    this.options_controller = "/init/api/request/options"; // Где брать options для полей
    this.style_controller = "/init/api/request/style"; // Где брать style
    this.stat_controller = "/init/api/request/build-time"; // Куда отправить стату по формам
    this.stat_widgets_controller = "/videowidget/stat"; // Куда отправить стату по виджетам
    this.next_controller = "/send/api/request/next"; // Куда отправить блок квиза
    this.send_quiz_controller = "/send/api/request/send-quiz"; // Куда отправить весь квиз
    this.agreement_controller = "/init/api/request/agreement"; // Где брать agreement
    this.localization_controller = "/init/api/request/localization"; // Где брать localization

    this.publicKey = 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBANwKPZ1D/3r/mkcXAHM7roXEI7/8fM2jqf3dIhDlkGacLuaajG+KfFEyw7Muq6whhLwMMGZblpCISXpIAe2ADEECAwEAAQ==';
    this.cookie_name_refer = "qform_refer"; // Название cookie Рефера
    this.cookie_name_utm = "qform_utm"; // Название cookie UTM-метки
    this.cookie_name_google_client_id = "qform_google_client_id"; // Название cookie Google ID
    this.cookie_name_yandex_client_id = "qform_yandex_client_id"; // Название cookie Yandex ID
    this.forms = {};
    // this.formUniqIds = null;
    // this.emitterForms = new Emitter();
    // this.emitterStatistics = new Emitter();
    this.startTime = parseInt(new Date().getTime()); // Время начала работы скрипта
    this.urlDatePickerJS = `${hostCloud}/js/Qdatepicker.js?v=${new Date().getTime() / 1000}`;
    this.urlFileFieldJS = `${hostCloud}/js/Qfilefield.js?v=${new Date().getTime() / 1000}`;
    this.QFormFlags = {
      AlreadyInit: false
    };

    // *here* location.hostname || "huge-sales.ru" || "ru-mastera.ru" Домен где работаем
    // on development using .env.development HOSTNAME='you.hostname.loc_dom'
    this.domain = document.location.host;
  }
}