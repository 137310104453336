export class DomListener {
  constructor($root) {
    let listeners = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    if (!$root) {
      throw new Error(`No $root provided for DomListener!`);
    }
    this.$root = $root;
    this.listeners = listeners;
  }
  initDomListener() {
    this.listeners.forEach(listener => {
      // // console.log(listener);
      if (!this[listener.method]) {
        const nameComponent = this.name || "(не известен)";
        throw new Error(`QForm: "Не найден метод ${listener.method} в компоненте ${nameComponent}"`);
      }
      this[listener.method] = this[listener.method].bind(this);
      this.$root.on(listener.eventName, this[listener.method], listener.el);
    });
  }
  removeDomListener() {
    this.listeners.forEach(listener => {
      this.$root.off(listener.eventName, this[listener.method], listener.el);
    });
  }
}